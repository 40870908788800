class Region
{
    static get $select() {
        return $('#reg_select');
    }

    static get $border() {
        return Region.$select.siblings().find('span').eq(0).find('.select2-selection');
    }

    static addWarning() {
        Region.$border.addClass('warn-border');
    }

    static removeWarning() {
        Region.$border.removeClass('warn-border');
    }

    static setValue(value, triggerChange = false) {
        Region.$select.val(value);
        if (triggerChange) {
            Region.$select.trigger('change');
        }
    }

    static change() {
        Region.$select.trigger('change');
    }
}

export default Region;