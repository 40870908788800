import ContactUs from './contact-us';

class GeneralContactUs extends ContactUs
{
    bindFormSubmit() {
        super.bindFormSubmit(() => {
            const $wrapper = $('.hospital_contact');
            const $hospital = $wrapper.find('#hospital');
            const $natureOfEnquiry = $wrapper.find('#nature-of-enquiry');

            $hospital.closest('.input-group').removeClass('error');

            if ($hospital.val() === '') {
                $hospital.closest('.input-group').addClass('error');
                return 1;
            }

            super.setRecipientName($hospital.find('option:selected').text());
            super.setRecipientEmail($hospital.val());
            super.setNatureOfEnquiry($natureOfEnquiry.val());

            return 0;
        });
    };
}

export default GeneralContactUs;
