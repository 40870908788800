class Specialty
{
    static get $select() {
        return $('#spec_select');
    }

    static isEmpty() {
        return Specialty.$select.find('option').length === 1;
    }

    static reset() {
        Specialty.$select.empty().append('<option value="">Specialties</option>');
    }

    static append(value, label) {
        Specialty.$select.append(`<option value="${value}">${label}</option>`);
    }

    static setValue(value, triggerChange = false) {
        Specialty.$select.val(value);
        if (triggerChange) {
            Specialty.$select.trigger('change');
        }
    }

    static updateItems(data, defaultValue = null) {
        const regex = /data\-specialty\=\"(.*)\"\s*\>(.*)\<\/a\>/i;
        const regexGroup = /data\-specialty\=\"(.*)\"\s*\>(.*)\<\/a\>/ig;
        const matched = data.match(regexGroup);

        if (matched) {
            Specialty.reset();

            matched.forEach(matched => {
                const pieces = matched.match(regex);
                Specialty.append(pieces[1], pieces[2]);
            });

            if (defaultValue) {
                Specialty.setValue(defaultValue);
            }
        }
    }

    static loadItemsByRegion(region, defaultValue = null) {
        $.get(`/specialty/search?region=${region}`, data => Specialty.updateItems(data, defaultValue));
    }
}

export default Specialty;