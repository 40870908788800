import LocationMap from "./location-map";
import SpecialtyMap from "./specialty-map";

class SouthernCrossMaps {
  constructor(locationMapId, regionMapId) {
    this.locationMapId = locationMapId;
    this.regionMapId = regionMapId;
    this.locationData = $("#loc_data").data("info");
    this.regionData = $("#reg_data").data("info");
  }

  run() {
    if ($(`#${this.locationMapId}`).length) {
      this.loadLocationsMap();
    } else if ($(`#${this.regionMapId}`).length) {
      this.loadSpecialistsMap();
    }
  }

  loadLocationsMap() {
    const init = () =>
      new LocationMap(this.locationData, this.locationMapId).load();

    if (!this.locationData) {
      $.get("/actions/payment-express/default/all-locations", (data) => {
        this.locationData = JSON.parse(data);
        //console.log(this.locationData);
        init();
      });
    } else {
      init();
    }
  }

  loadSpecialistsMap() {
    const $loading = $("#loading-info")
      .closest(".container")
      .removeClass("hidden");
    const init = () => {
      new SpecialtyMap(this.regionData, this.regionMapId).load();
      $loading.addClass("hidden");
    };

    if (!this.regionData) {
      $.get("/actions/payment-express/default/region-specialists", (data) => {
        this.regionData = JSON.parse(data);
        init();
      });
    } else {
      init();
    }
  }
}

export default SouthernCrossMaps;
