class SearchResult
{
    static get $panel() {
        return $('#search-result');
    }

    static get $message() {
        return $('.custom-message');
    }

    static clear(message) {
        SearchResult.$panel.empty();
        $('#s_header').hide();
        $('#r_header').hide();
        $('.reset_search').removeClass('hidden');

        if (message) {
            SearchResult.$message.html(message).fadeIn();
        }
    }

    static hideMessage() {
        SearchResult.$message.hide();
    }

    static fillContent(specLabel, spec, region) {
        const s_header = $('#s_header'),
            r_header = $('#r_header'),
            s_region = $('#s_region'),
            s_title = $('#spec_title'),
            spec_region = $('#spec_region'),
            s_grid = $('#s_grid'),
            speciality_word = specLabel !== '' ? specLabel.replace('amp;', '') : 'Surgical',
            region_word = region !== '' ? region.replace('-', ' ') : 'New Zealand';

        if (region) {
            s_header.show();
            s_grid.hide();
            r_header.hide();

            if (spec) {
                s_header.hide();
                r_header.fadeIn();
            }
        } else if (spec) {
            s_header.hide();
            s_grid.show();
            r_header.hide();
        }
        s_region.text(region_word);
        spec_region.text(region_word);
        s_title.text(speciality_word.replace(/\(\d+\)/, ''));
        s_title.data('spec-slug', spec);

        SearchResult.hideMessage();
    }

    static indicateLoadingData() {
        SearchResult.$panel.html('Loading data ...');
    }

    static updatePanel(data, callback) {
        SearchResult.$panel.hide().html(data).fadeIn().promise().done(callback);
    }

    static showOnlyRegionHeader() {
        $('#s_header').hide();
        $('#r_header').fadeIn();
    }
}

export default SearchResult;