class ContactUs {
  constructor(formId) {
    this.$form = $(`#${formId}`);

    if (this.$form.length) {
      this.$buttonSubmit = this.$form.find('[type="submit"]');
      this.$displayMessage = this.$form.find(".display-message");
      this.$inputGroupToHide = this.$form.find(".input-group");
    }
  }

  bindFormSubmit(beforeSubmit, afterSubmit) {
    const $form = this.$form;

    if ($form.length) {
      $form.on("submit", (e) => {
        e.preventDefault();

        let error = 0;

        $(".sproutforms-message-success").html("");

        $form.find(".input-group").removeClass("error");

        $form.find(".form-control").each((index, element) => {
          const $cur = $(element);
          if ($cur.val().replace(/\s+/, "") === "") {
            $cur.closest(".input-group").addClass("error");
            error += 1;
          }
        });

        if (typeof beforeSubmit === "function") {
          error += beforeSubmit();
        }

        if (error > 0) {
          return false;
        }

        this.disableSubmit();

        // $.post("/", $form.serialize(), (response) => {
        setTimeout(() => {
          this.enableSubmit();
          if (typeof afterSubmit === "function") {
            afterSubmit(response);
          } else {
            this.showSuccess($form.data("success-message"));
          }
        }, 3000);

        // });
      });
    }

    return this;
  }

  disableSubmit() {
    if (this.$buttonSubmit.length) {
      this.$buttonSubmit
        .addClass("submitting")
        .val("Sending...")
        .prop("disabled", true)
        .find(".text")
        .text("Sending...");
    }
    return this;
  }

  enableSubmit() {
    if (this.$buttonSubmit.length) {
      this.$buttonSubmit
        .removeClass("submitting")
        .val("Submit")
        .prop("disabled", false)
        .find(".text")
        .text("Submit");
    }
    return this;
  }

  showSuccess(message) {
    if (this.$displayMessage.length) {
      this.$inputGroupToHide.find("input, textarea").val("");
      this.$displayMessage
        .removeClass("fail")
        .addClass("success")
        .empty()
        .text(message)
        .fadeIn();
    }
    return this;
  }

  showFail(message) {
    if (this.$displayMessage.length) {
      this.$displayMessage
        .removeClass("success")
        .addClass("fail")
        .empty()
        .text(message)
        .fadeIn();
    }
    return this;
  }

  setRecipientName(value) {
    return this.setFieldValue("toName", value);
  }

  setRecipientEmail(value) {
    return this.setFieldValue("fields[toEmail]", value);
  }
  setRecipientHosptial(value) {
    return this.setFieldValue("fields[toHospital]", value);
  }

  setNatureOfEnquiry(value) {
    return this.setFieldValue("natureOfEnquiry", value);
  }

  setHospitalSlug(value) {
    return this.setFieldValue("hospitalSlug", value);
  }

  setFieldValue(fieldName, fieldValue) {
    if (this.$form.length) {
      const $field = this.$form.find(`[name="${fieldName}"]`);
      if ($field.length) {
        $field.val(fieldValue);
      }
    }
    return this;
  }
}

export default ContactUs;
