import MarkerClusterer from '@bigyouth/markerclustererplus';
import MapStyler from './map-styler';

class LocationMap
{
    constructor(data, mapId) {
        this.map = null;
        this.locations = [];
        this.markers = [];
        this.markerCluster = null;
        this.initLocations(data);
        this.createMap(mapId);
    }

    load() {
        this.createMarkers();
        this.createMarkerCluster();
        this.watchZoomLevel();
    }

    watchZoomLevel() {
        let zoomLevel = 1;

        google.maps.event.addListener(this.map, 'zoom_changed', () => {
            const prevZoomLevel = zoomLevel;
            zoomLevel = this.map.getZoom() < 10 ?  1 : 2;
            if (prevZoomLevel !== zoomLevel) {
                this.markers.forEach(marker => marker.setIcon(zoomLevel === 2 ? marker.iconLevel2 : marker.iconLevel1));
            }
        });
    }

    createMap(mapId) {
        return this.map = new google.maps.Map(document.getElementById(mapId), {
            zoom: 6,
            center: new google.maps.LatLng(-40.419381, 171.911821),
            styles: MapStyler.getStyles(),
            scrollwheel: false,
            disableDefaultUI: true,
            scaleControl: true,
            maxZoom: 19
        });
    }

    initLocations(data) {
        if (data) {
            this.locations = data.map(i => {
                return {
                    lat: parseFloat(i.lat),
                    lng: parseFloat(i.lng),
                    text: i.title,
                    cat: i.cat,
                    slug: i.slug,
                    partner: i.partner,
                    partnerLandingLink: i.partnerLandingLink,
                    url: i.url
                };
            });
            return true;
        }
        return false;
    }

    createInfoWindow(location) {
        const title = location.text;
        const content = location.partner == 1
            ? location.partnerLandingLink == null ? title : `<a href="${location.partnerLandingLink}" target="_blank" rel="noopener noreferrer">${title}</a>`
            : `<a href="${location.url}">${title}</a>`;
        return new google.maps.InfoWindow({content: content});
    }

    createMarker(location) {
        const marker = new google.maps.Marker({
            position: location,
            category: location.cat,
            icon: {
                url: '/images/m321.png',
                size: new google.maps.Size(29, 29),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(17, 27),
                scaledSize: new google.maps.Size(29, 29)
            },
            iconLevel1: '/images/m321.png',
            iconLevel2: '/images/location-icon.png',
            infoWindow: this.createInfoWindow(location)
        });

        marker.addListener('click', () => {
            this.map.setCenter(marker.getPosition());
            this.map.setZoom(15);
            marker.infoWindow.open(this.map, marker);
        });

        return marker;
    }

    createMarkers() {
        const bounds = new google.maps.LatLngBounds();
        this.markers = this.locations.map(location => this.createMarker(location));
        this.markers.forEach(marker => bounds.extend(marker.getPosition()));
        return this.markers;
    }

    createMarkerCluster() {
        this.markerCluster = new MarkerClusterer.MarkerClusterer(this.map, this.markers, {
            ignoreHidden: true,
            styles: [{
                textColor: '#ffffff',
                url: '/images/m311.png',
                height: 29,
                width: 29,
                textSize: 14
            }],
            gridSize: 21,
        });

        this.markerCluster.setClusterClass('cluster__');
        google.maps.event.addListener(this.markerCluster, "clusterclick", c => this.openInfoWindowInCluster(c));

        return this.markerCluster;
    }

    openInfoWindowInCluster(cluster) {
        cluster.getMarkers().forEach(marker => marker.infoWindow.open(this.map, marker));
    }
}

export default LocationMap;
