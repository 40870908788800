// import all self-hosted web fonts
function requireAll(r) {
    r.keys().forEach(r);
}
requireAll(require.context('../fonts/fontawesome-pro', true, /\.woff2$/));

import '../less/styles.less';
import 'bootstrap';
import '../script.js';
import './printThis.js';
import './express-form-ajax.js';

window.jQuery = $;
window.$ = $;