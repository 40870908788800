import MarkerClusterer from "@bigyouth/markerclustererplus";
import MapStyler from "./map-styler";
import Specialty from "./dropdown/specialty";
import Region from "./dropdown/region";
import SearchResult from "./search-result";

class SpecialtyMap {
  constructor(data, mapId) {
    this.zoomLevel = 1;
    this.center = new google.maps.LatLng(-40.419381, 175.911821);
    this.map = null;
    this.regionCluster = null;
    this.doctorCluster = null;
    this.regionMarkers = [];
    this.doctorMarkers = [];
    this.markerImage = "/images/m321.png";
    this.dMarkerImage = "/images/s221.png";
    this.regions = data;
    this.map = new google.maps.Map(document.getElementById(mapId), {
      zoom: 6,
      center: this.center,
      styles: MapStyler.getStyles(),
      scrollwheel: false,
      disableDefaultUI: true,
      scaleControl: true,
      maxZoom: 15,
    });
  }

  load() {
    this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(
      document.getElementById("reset_link")
    );
    this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(
      document.getElementById("map_legend")
    );

    this.createMarkers();
    this.createRegionCluster();
    this.createDoctorCluster();
    this.watchZoomLevel();
    this.bindFindSpecialist();
  }

  createMarkers() {
    for (let r = 0; r < this.regions.length; r++) {
      const region = this.regions[r];

      for (let h = 0; h < region.hospitals.length; h++) {
        let specWords = "";

        const hospital = region.hospitals[h];
        const regionValue = region.region_slug;

        for (let hs = 0; hs < hospital.specialties.length; hs++) {
          const speciality = hospital.specialties[hs];

          for (let sd = 0; sd < speciality.doctors.length; sd++) {
            this.createDoctorMarker(hospital, speciality, regionValue, sd);
            specWords = specWords + " " + speciality.slug;
          }
        }

        this.createHospitalMarker(hospital, specWords, regionValue);
      }
    }
  }

  createDoctorMarker(hospital, speciality, region, doctorIndex) {
    const marker = new google.maps.Marker({
      position: {
        lat: parseFloat(hospital.lat),
        lng: parseFloat(hospital.lng),
      },
      person_slug: speciality.doctors[doctorIndex].slug,
      category: speciality.slug + " " + region,
      icon: {
        url: this.dMarkerImage,
        size: new google.maps.Size(50, 50),
        origin: new google.maps.Point(0, 0),
        scaledSize: new google.maps.Size(50, 50),
      },
      markerType: "person",
      region: region,
    });
    this.doctorMarkers.push(marker);

    return marker;
  }

  createHospitalMarker(hospital, specWords, region) {
    const marker = new google.maps.Marker({
      position: {
        lat: parseFloat(hospital.lat),
        lng: parseFloat(hospital.lng),
      },
      h_title: hospital.title,
      h_slug: hospital.slug,
      icon: {
        url: this.markerImage,
        size: new google.maps.Size(29, 29),
        origin: new google.maps.Point(0, 0),
        scaledSize: new google.maps.Size(29, 29),
      },
      iconLevel1: "/images/m321.png",
      iconLevel2: "/images/location-icon.png",
      markerType: "hospital",
      region: region,
      category: region + " " + specWords,
      infoWindow: this.createInfoWindow(hospital.slug, hospital.title),
    });

    marker.addListener("click", () => {
      Region.setValue(marker.region, true);
      marker.infoWindow.open(this.map, marker);
    });

    this.regionMarkers.push(marker);

    return marker;
  }

  createRegionCluster() {
    const cluster = new MarkerClusterer.MarkerClusterer(
      this.map,
      this.regionMarkers,
      {
        imagePath: "/images/m31",
        ignoreHidden: true,
        styles: [
          {
            textColor: "white",
            url: "/images/m411.png",
            height: 29,
            width: 29,
            textSize: 14,
            anchorIcon: [27, 47],
          },
        ],
        gridSize: 20,
      }
    );

    cluster.setClusterClass("cluster_2");

    google.maps.event.addListener(cluster, "clusterclick", (c) =>
      Region.setValue(c.getMarkers()[0].region, true)
    );

    return (this.regionCluster = cluster);
  }

  createDoctorCluster() {
    const cluster = new MarkerClusterer.MarkerClusterer(
      this.map,
      this.doctorMarkers,
      {
        ignoreHidden: true,
        styles: [
          {
            textColor: "white",
            url: "/images/r411.png",
            height: 54,
            width: 39,
            textSize: 14,
            anchorIcon: [12, 37],
          },
        ],
        minimumClusterSize: 1,
        zoomOnClick: false,
        gridSize: 20,
      }
    );

    cluster.setClusterClass("cluster_3");

    return (this.doctorCluster = cluster);
  }

  createInfoWindow(slug, title) {
    const $title = $("#spec_title");
    const link = `/our-healthcare-services/${slug}`;
    const extraLink =
      $title.text() !== "Surgical"
        ? `&specialty=${$title.data("spec-slug")}`
        : "";

    return new google.maps.InfoWindow({
      content: `<a href="${link}${extraLink}">${title}</a>`,
    });
  }

  watchZoomLevel() {
    google.maps.event.addListener(this.map, "zoom_changed", () => {
      if (this.map.getZoom() < 6) {
        this.map.setZoom(6);
        return;
      }

      const prevZoomLevel = this.zoomLevel;

      this.map.getZoom() < 10 ? (this.zoomLevel = 1) : (this.zoomLevel = 2);

      if (prevZoomLevel !== this.zoomLevel) {
        this.regionMarkers.forEach((marker) => {
          let style = {
            textColor: "#008BCE",
            url: "/images/location-icon.png",
            textSize: 1,
            anchorIcon: [10, 30],
          };

          if (this.zoomLevel === 1) {
            style = {
              textColor: "white",
              url: "/images/m211.png",
              textSize: 12,
              anchorIcon: [27, 47],
            };
          }

          this.regionCluster.setStyles([
            $.extend({ height: 54, width: 54 }, style),
          ]);
          marker.setIcon(
            this.zoomLevel === 2 ? marker.iconLevel2 : marker.iconLevel1
          );
        });
      }
    });
  }

  bindFindSpecialist() {
    const specialtySelect = document.getElementById("spec_select");
    const regionSelect = document.getElementById("reg_select");

    let regionValue = regionSelect.value | "";
    let specialtyLabel = "";
    let specialtyValue = specialtySelect.value | "";

    const $mapBlock = $(".block-spec-map");

    specialtySelect.onchange = (e) => {
      if (regionSelect.value === "") {
        Region.addWarning();
      } else {
        Region.removeWarning();

        const element = e.currentTarget;
        specialtyValue = element.value;
        specialtyLabel = element[element.selectedIndex].innerHTML;

        if (!$mapBlock.hasClass("selection_process")) {
          $mapBlock.addClass("selection_process");
          google.maps.event.trigger(this.map, "resize");
        }

        const regionLabel = regionSelect[regionSelect.selectedIndex].innerHTML;
        this.refreshSelection(
          specialtyLabel,
          specialtyValue,
          regionLabel,
          regionValue
        );
      }
    };

    regionSelect.onchange = (e) => {
      Region.removeWarning();

      regionValue = e.currentTarget.value;
      specialtyValue = specialtySelect.value;
      specialtyLabel = specialtySelect[specialtySelect.selectedIndex]
        ? specialtySelect[specialtySelect.selectedIndex].innerHTML
        : "";

      if (!$mapBlock.hasClass("selection_process")) {
        $mapBlock.addClass("selection_process");
        google.maps.event.trigger(this.map, "resize");
      }

      Specialty.reset();

      const regionLabel = regionSelect[regionSelect.selectedIndex].innerHTML;
      this.refreshSelection(
        specialtyLabel,
        specialtyValue,
        regionLabel,
        regionValue
      );
    };

    if (regionSelect.value !== "") {
      Region.change();
    }
  }

  refreshSelection(specLabel, specValue, regionLabel, regionValue) {
    const s = specValue !== 0 ? specValue : "";
    const r = regionValue !== 0 ? regionValue : "";
    const found1 = this.searchMarkers(
      s,
      r,
      this.regionMarkers,
      this.regionCluster
    );
    const found2 = this.searchMarkers(
      s,
      r,
      this.doctorMarkers,
      this.doctorCluster
    );

    if (found1 || found2) {
      SearchResult.fillContent(specLabel, s, regionLabel);

      this.fitVisibleMarkers();
      this.search(s, r);
    } else {
      const sl = specLabel.replace(/(\s*\(\d+\)|amp;|Specialties)/g, "");
      const region = regionLabel.replace(/amp;/g, "");
      const specialty = sl ? `“${sl}” ` : "";

      SearchResult.clear(`We’re sorry, but it looks like we currently don’t have any Southern Cross wholly-owned hospitals in that region listing specialists in the selected discipline.<br><br>
            Please feel free to search another region or contact the nearest hospital on our network (see <a href="/specialists-and-services/our-healthcare-services">our-healthcare-services</a>).<br><br> We are always happy to try to help you.`);
      Specialty.loadItemsByRegion(regionValue);

      this.closeInfoWindows();
      this.loadCenteredGrayMap();
    }
  }

  closeInfoWindows() {
    this.regionMarkers.forEach((marker) => marker.infoWindow.close());
  }

  loadCenteredGrayMap() {
    const bounds = new google.maps.LatLngBounds();

    this.regionMarkers.forEach((marker) => {
      bounds.extend(marker.getPosition());
    });

    this.map.fitBounds(bounds);
    this.map.setCenter(this.center);
    this.map.setZoom(4);
  }

  searchMarkers(spec, region, markers, cluster) {
    let found = false;

    markers.forEach((marker) => {
      const matched =
        marker.category.indexOf(region) > -1 &&
        marker.category.indexOf(spec) > -1;

      marker.setVisible(matched);
      cluster.repaint();

      if (matched) {
        found = matched;
      }
    });

    return found;
  }

  fitVisibleMarkers() {
    const bounds = new google.maps.LatLngBounds();

    this.regionMarkers.forEach((marker) => {
      const infoWindow = marker.infoWindow;

      if (marker.getVisible()) {
        bounds.extend(marker.getPosition());
        infoWindow.open(this.map, marker);
      } else {
        infoWindow.close();
      }
    });

    this.doctorMarkers.forEach((marker) => {
      if (marker.getVisible()) {
        bounds.extend(marker.getPosition());
      }
    });

    this.map.fitBounds(bounds);
    this.map.setCenter(bounds.getCenter());

    if (this.map.getZoom() > 15) {
      this.map.setZoom(15);
    }
  }

  search(specialty, region) {
    this.resetInfoWindowContent();

    SearchResult.showOnlyRegionHeader();
    SearchResult.indicateLoadingData();

    let url = "";

    if (region) {
      if (specialty) {
        url = `/specialist/search?region=${region}&specialty=${specialty}`;
      } else {
        url = `/specialty/search?region=${region}`;
      }
    }

    this.updateInfoWindowContent(specialty);

    $.get(url, (data) => {
      if (!specialty) {
        Specialty.updateItems(data);
      }
      data = this.sortDataBySurname(data);
      SearchResult.updatePanel(data, () => {
        SpecialtyMap.postLoadingData();

        if (Specialty.isEmpty()) {
          Specialty.loadItemsByRegion(region, specialty);
        }
      });
    });
  }

  updateInfoWindowContent(specialty = null) {
    this.regionMarkers.forEach((m, i) => {
      if (m.getVisible()) {
        const alternative = specialty
          ? "?section=specialists&specialty=" + specialty + '">'
          : '?section=specialists">';
        this.regionMarkers[i].infoWindow.setContent(
          m.infoWindow.content.replace('">', alternative)
        );
      }
    });
  }

  resetInfoWindowContent() {
    this.regionMarkers.forEach((m, i) => {
      this.regionMarkers[i].infoWindow.setContent(
        m.infoWindow.content.replace(/(\?.*)("\s*>)/, "$2")
      );
    });
  }

  sortDataBySurname(data) {
    let htmlData = $.parseHTML(data.trim());
    let specialists = $(htmlData[0]).find(".specialist");
    if (specialists.length > 1) {
      specialists.sort(function (a, b) {
        if (
          $(a).attr("data-surname").toLowerCase() >
          $(b).attr("data-surname").toLowerCase()
        ) {
          return 1;
        } else if (
          $(a).attr("data-surname").toLowerCase() ===
          $(b).attr("data-surname").toLowerCase()
        ) {
          return 0;
        } else {
          return -1;
        }
      });
    }
    $(htmlData[0]).append(specialists);
    return htmlData[0].outerHTML;
  }

  static addSpecialistLinkToProfilePhoto() {
    $(".specialist .photo").on(
      "click",
      (e) => (document.location.href = $(e.currentTarget).data("link"))
    );
  }

  static bindQueryRegionSpecialty() {
    $(".query-region-specialty").on("click", (e) => {
      e.preventDefault();

      SearchResult.indicateLoadingData();

      const $target = $(e.currentTarget);
      const region = $target.data("region");

      if (region) {
        Region.setValue(region);
      }

      Specialty.setValue($target.data("specialty"), true);
    });
  }

  static postLoadingData() {
    SpecialtyMap.bindQueryRegionSpecialty();
    SpecialtyMap.addSpecialistLinkToProfilePhoto();
  }
}

export default SpecialtyMap;
