import SouthernCrossMaps from "./southern-cross-maps";

// User defined map ID
const MAP_ID_LOCATION = "map";
const MAP_ID_SPECIALTY = "spec_map";

window.initMap = function () {
  const m = new SouthernCrossMaps(MAP_ID_LOCATION, MAP_ID_SPECIALTY);
  m.run();
};
function initGoogleMap() {
  if (window.google.maps) {
    window.initMap();
  } else {
    setTimeout(initGoogleMap, 500);
  }
}
jQuery(function () {
  var isLoaded = false;
  setTimeout(function () {
    console.log(isLoaded);
    if (!isLoaded) {
      if (
        typeof window.google !== "undefined" &&
        typeof window.google.maps !== "undefined"
      ) {
        initGoogleMap();
        isLoaded = true;
      }
    }
  }, 3000);
  setTimeout(function () {
    console.log(isLoaded);
    if (!isLoaded) {
      if (
        typeof window.google !== "undefined" &&
        typeof window.google.maps !== "undefined"
      ) {
        initGoogleMap();
        isLoaded = true;
      }
    }
  }, 3000);
  setTimeout(function () {
    console.log(isLoaded);
    if (!isLoaded) {
      if (
        typeof window.google !== "undefined" &&
        typeof window.google.maps !== "undefined"
      ) {
        initGoogleMap();
        isLoaded = true;
      }
    }
  }, 5000);
});
