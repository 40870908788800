import ContactUs from "./contact-us";

class ModalContactUs extends ContactUs {
  constructor(formId) {
    super(formId);

    if (this.$form.length) {
      const $modal = this.$form.parents(".contact-modal");
      const $modalTriggers = $(`[data-target="#${$modal[0].id}"]`);
      if ($modal.length && $modalTriggers.length) {
        $modalTriggers.on("click", (e) => { 
          let $trigger;
          if ( $modalTriggers.hasClass( "external-trigger" ) ) {
            $trigger = $( `a.flex_hor[data-target="#${$modal[0].id}"]` );
          } else {
            $trigger = $(e.currentTarget);
          }
          $modal
            .find("#for_title")
            .text($trigger.data("title"))
            .end()
            .find(".input-group")
            .show()
            .find("input, textarea")
            .val("");
          $modal.find(".display-message").empty();
          this.setHospitalSlug($trigger.data("slug"));
        });
      }
    }
  }
}

export default ModalContactUs;
